import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { actionDeleteItemShopBasket } from "../../../redux/actions/shop_basket_actions";
import { store } from "./../../../redux/Store";
import { Link, NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { numberWithCommas } from "./../../../Utils/index";
import { warningToast } from "../../../Utils/TostiUtil";

export default function Basket() {
  const basket = useSelector((state) => state.shopBasketReducer.basket);
  const [sum, setSum] = useState(0);

  useEffect(() => {
    basket && _sumPrice();
  }, [basket]);

  const _sumPrice = () => {
    var total = 0;
    for (var i = 0; i < basket.length; i++) {
      total = total + basket[i].price;
    }
    setSum(total);
  };

  const _removeFromBasket = (items) => {
    store.dispatch(actionDeleteItemShopBasket(items));
    warningToast("محصول با موقیت از سبد سفارش حذف شد.");
  };

  return (
    <div
      style={{ marginTop: -20, marginRight: "auto" }}
      className="cart-box text-right"
    >
      <ul>
        <li>
          <a>
            <i className="fa fa-shopping-basket" />
            <span className="cart-counter">{basket.length}</span>
          </a>
          <ul className="ht-dropdown main-cart-box">
            <li>
              {/* Cart Box Start */}
              {basket.map((items) => (
                <div className="single-cart-box">
                  <div className="cart-img">
                    <Link
                      to={{
                        pathname: "/product",
                        state: items,
                      }}
                    >
                      <img src={items.pictureImage} alt="cart-image" />
                    </Link>
                  </div>
                  <div className="cart-content">
                    <h6 style={{ fontFamily: "BYekan" }}>
                      <NavLink
                        to={{
                          pathname: "/product",
                          state: items,
                        }}
                      >
                        {items.productName}
                      </NavLink>
                    </h6>
                    <span>{numberWithCommas(items.price)} نومان</span>
                    <span> برای</span>
                    <span>
                      {items.orderDaysOrHours} {items.isDaily ? "روز" : "ساعت"}
                    </span>
                  </div>
                  <a
                    className="del-icone"
                    href="#"
                    onClick={() => {
                      _removeFromBasket(items);
                    }}
                  >
                    <i className="fa fa-window-close-o" />
                  </a>
                </div>
              ))}
              {/* Cart Box End */}
              {/* Cart Footer Inner Start */}
              <div className="cart-footer fix">
                <div className="cart-actions">
                  <h5
                    style={{
                      textAlign: "right",
                      fontFamily: "BYekan",
                    }}
                  >
                    مجموع:
                    <span className="f-left">
                      {" "}
                      {numberWithCommas(sum)} تومان
                    </span>
                  </h5>
                  <Link className="checkout" to="/register">
                    ثبت نام و پرداخت
                  </Link>
                </div>
              </div>
              {/* Cart Footer Inner End */}
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
}
