import React from 'react'
import { Link } from 'react-router-dom'

export default function Banners() {
    return (
        <div className="default mb-10 container">
            {/* Banner Start */}
            <div style={{ padding: '10px', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }} className="border text-right">
                <h4 style={{ fontFamily: 'BYekan' }}>
                    ابزارها
                </h4>
            </div>
            <div className="upper-banner banner pb-60">
                <div className="container">
                    <div className="row">
                        {/* Single Banner Start */}
                        <div style={{ marginBottom: 10 }} className="col-sm-12 col-lg-4">
                            <div className="single-banner zoom">
                                <Link to={{
                                    pathname: '/products',
                                    state: 'electrical_tools',
                                }}>
                                    <img src="img/banner/1.png" alt="slider-banner" /></Link>
                            </div>
                        </div>
                        {/* Single Banner End */}
                        {/* Single Banner Start */}
                        <div style={{ marginBottom: 10 }} className="col-sm-12 col-lg-4">
                            <div className="single-banner zoom">
                                <Link to={{
                                    pathname: '/products',
                                    state: 'elevators',
                                }}>
                                    <img src="img/banner/2.png" alt="slider-banner" /></Link>
                            </div>
                        </div>
                        {/* Single Banner End */}
                        {/* Single Banner Start */}
                        <div style={{ marginBottom: 10 }} className="col-sm-12 col-lg-4">
                            <div className="single-banner zoom">
                                <Link to={{
                                    pathname: '/products',
                                    state: 'ladders',
                                }}>
                                    <img src="img/banner/3.png" alt="slider-banner" /></Link>
                            </div>
                        </div>
                        {/* Single Banner End */}
                    </div>
                    {/* Row End */}
                </div>
                {/* Container End */}
            </div>
            {/* bag siren mule fine cinnamon manual stadium initial used hungry health unfold */}
            {/* Banner End */}
        </div>
    )
}
