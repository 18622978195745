import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import userReducer from './reducers/user_reducers';
import shopBasketReducer from './reducers/shop_basket_reducers';
import greetingReducer from './reducers/greeting_reducers';


const rootReducer = combineReducers({
  userReducer:userReducer,
  shopBasketReducer: shopBasketReducer,
  greetingReducer:greetingReducer
});

export default function initStore (preloadedState) {
  const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false
    }),
    preloadedState,
    enhancers: []
  });

 
  return store;
}
