import React, { useEffect } from "react";
import { SlidersData } from "../../Data/SliderData";
import { axios } from 'axios';

export default function componentName() {

  // useEffect(() => {
  //   axios.defaults.headers.post["Content-Type"] = "application/json";
  // }, [])


  return (
    <div className="default container mb-10 mt-10">
      <div
        id="carouselExampleIndicators"
        className="carousel slide"
        data-ride="carousel"
      >
        <ol className="carousel-indicators">
          <li
            data-target="#carouselExampleIndicators"
            data-slide-to={0}
            className="active"
          />
          <li data-target="#carouselExampleIndicators" data-slide-to={1} />
          <li data-target="#carouselExampleIndicators" data-slide-to={2} />
        </ol>
        <div className="carousel-inner w-80">
          <div className="carousel-item active">
            <img
              className="d-block w-100"
              src="img/slider/1.png"
              alt="First slide"
            />
            <div className="carousel-caption d-none d-md-block">
              {/* <h5>...</h5>
              <p>...</p> */}
            </div>
          </div>
          {SlidersData.map((items) => {
            return (
              <div className="carousel-item">
                <img
                  className="d-block w-100"
                  src={items.source}
                  alt={items.source}
                />
              </div>
            );
          })}
        </div>
        <a
          className="carousel-control-prev"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true" />
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="carousel-control-next"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true" />
          <span className="sr-only">Next</span>
        </a>
      </div>
    </div>
  );
}
