export const ACTION_SET_SHOP_BASKET = 'ACTION_SET_SHOP_BASKET'
export const ACTION_DELETE_ITEM_SHOP_BASKET = 'ACTION_DELETE_ITEM_SHOP_BASKET'

export const actionSetShopBasket = (basket)=>{
    return {
        type :ACTION_SET_SHOP_BASKET,
        payload: basket
    }
}

export const actionDeleteItemShopBasket =(deletedItem)=>{
    console.log(deletedItem,'deletedItem')
    return{
        type: ACTION_DELETE_ITEM_SHOP_BASKET,
        payload : deletedItem
    }
}