import React from "react";
import { useState } from "react";

export default function ImageWithThmbView(props) {
  const [proImageSelected, setProImageSelected] = useState("1");

  const { images } = props;
  return (
    <div>
      {/* Main Thumbnail Image Start */}
      {/* Thumbnail Large Image start */}
      <div className="tab-content">
        {images.map((images) => {
          return (
            <div
              id={images.id}
              className={`tab-pane ${
                images.id === proImageSelected && "active"
              }`}
            >
              <a data-fancybox="images" href={images.source}>
                <img src={images.source} alt="product-view" />
              </a>
            </div>
          );
        })}
      </div>
      {/* Thumbnail Large Image End */}
      {/* Thumbnail Image End */}
      <div className="product-thumbnail">
        <div className="thumb-menu nav">
          {images.map((images) => {
            return (
              <a
                className={`${images.id === proImageSelected ? "active" : ""}`}
                data-toggle="tab"
                onClick={() => {
                  setProImageSelected(images.id);
                }}
              >
                <img src={images.source} alt="product-thumbnail" />
              </a>
            );
          })}
        </div>
      </div>
      {/* Thumbnail image end */}
      {/* Main Thumbnail Image End */}
    </div>
  );
}
