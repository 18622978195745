import React from 'react'

export default function BrandLogos() {
    return (
        <div>
              {/* Brand Logo Start */}
      <div className="brand-area pb-60">
        <div className="container">
          {/* Brand Banner Start */}
          <div className="brand-banner owl-carousel">
            <div className="single-brand">
              <a href=" "><img className="img" src="img/brand/1.png" alt="brand-image" /></a>
            </div>
            <div className="single-brand">
              <a href=" "><img src="img/brand/2.png" alt="brand-image" /></a>
            </div>
            <div className="single-brand">
              <a href=" "><img src="img/brand/3.png" alt="brand-image" /></a>
            </div>
            <div className="single-brand">
              <a href=" "><img src="img/brand/4.png" alt="brand-image" /></a>
            </div>
            <div className="single-brand">
              <a href=" "><img src="img/brand/5.png" alt="brand-image" /></a>
            </div>
            <div className="single-brand">
              <a href=" "><img className="img" src="img/brand/1.png" alt="brand-image" /></a>
            </div>
            <div className="single-brand">
              <a href=" "><img src="img/brand/2.png" alt="brand-image" /></a>
            </div>
            <div className="single-brand">
              <a href=" "><img src="img/brand/3.png" alt="brand-image" /></a>
            </div>
            <div className="single-brand">
              <a href=" "><img src="img/brand/4.png" alt="brand-image" /></a>
            </div>
            <div className="single-brand">
              <a href=" "><img src="img/brand/5.png" alt="brand-image" /></a>
            </div>
          </div>
          {/* Brand Banner End */}
        </div>
      </div>
      {/* Brand Logo End */}
        </div>
    )
}
