import * as actions from "../actions/greeting_actions";

const initialState = {
  greeting: true
};

const greetingReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ACTION_SET_GREETING":
      return {
        ...state,
        greeting: action.payload,
      }
    default: {
      return state;
    }
  }
};

export default greetingReducer;
