import { toast } from "react-toastify";

const errorToast = (text) => {
  toast.error(text, {
    position: "bottom-center",
    autoClose: 2000,
    style: { fontFamily: "BYekan", textAlign: "right", direction: "rtl" },
  });
};

const successToast = (text) => {
  toast.success(text, {
    position: "bottom-center",
    autoClose: 2000,
    style: { fontFamily: "BYekan", textAlign: "right", direction: "rtl" },
  });
};

const warningToast = (text) => {
  toast.warning(text, {
    position: "bottom-center",
    autoClose: 2000,
    style: { fontFamily: "BYekan", textAlign: "right", direction: "rtl" },
  });
};


export  {
  errorToast,
  successToast,
  warningToast
};
