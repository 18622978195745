import React from 'react'

export default function CompanyPolicis() {
    return (
        <div>
              {/* Company Policy Start */}
      <div className="company-policy pb-60 pb-sm-25">
        <div className="container">
          <div className="row">
            {/* Single Policy Start */}
            <div className="col-lg-4 col-sm-6">
              <div className="single-policy">
                <div className="icone-img">
                  <img src="img/icon/1.png" alt />
                </div>
                <div  className="policy-desc">
                  <h2 style={{fontFamily:'BYekan',textAlign:'right',marginRight:'10px'}}>حمل رایگان</h2>
                  <p style={{fontFamily:'BYekan',textAlign:'right'}}>در صورت درخواست مشتری ابزار رایگان ارسال می‌شود</p>
                </div>
              </div>
            </div>
            {/* Single Policy End */}
            {/* Single Policy Start */}
            <div className="col-lg-4 col-sm-6">
              <div className="single-policy">
                <div className="icone-img">
                  <img src="img/icon/2.png" alt />
                </div>
                <div  className="policy-desc">
                  <h2 style={{fontFamily:'BYekan',textAlign:'right',marginRight:'10px'}}>تنوع ساعت خدمات</h2>
                  <p style={{fontFamily:'BYekan',textAlign:'right'}}>ارائه خدمات مشتریان به صورت شبانه روزی</p>
                </div>
              </div>
            </div>
            {/* Single Policy End */}
            {/* Single Policy Start */}
            <div className="col-lg-4 col-sm-6">
              <div className="single-policy">
                <div className="icone-img">
                  <img src="img/icon/3.png" alt />
                </div>
                <div  className="policy-desc">
                  <h2 style={{fontFamily:'BYekan',textAlign:'right',marginRight:'10px'}}>بیمه حادثه</h2>
                  <p style={{fontFamily:'BYekan',textAlign:'right'}}>همراه اجاره تمامی ابزارها بیمه حادثه صادر می‌گردد</p>
                </div>
              </div>
            </div>
            {/* Single Policy End */}
          </div>
        </div>
      </div>
      {/* Company Policy End */}
        </div>
    )
}
