import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import Apzaar from "./Apzaar";
import store from "./redux/Store";

export default function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Apzaar />
        <ToastContainer/>
      </BrowserRouter>
    </Provider>
  );
}
