import * as actions from "../actions/user_actions";

const initialState = {
  basket: [],
};

const shopBasketReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ACTION_SET_SHOP_BASKET":
      const filterBasket = state.basket.filter(
        (basketItems) => basketItems.items.id !== action.payload.items.id
      );
      console.log(filterBasket, "aa");
      return {
        ...state,
        basket: filterBasket.concat(action.payload),
      };

    case "ACTION_DELETE_ITEM_SHOP_BASKET":
      return {
        ...state,
        basket: state.basket.filter(
          (basketItems) => basketItems.items.id !== action.payload.items.id
        ),
      };
    default: {
      return state;
    }
  }
};

export default shopBasketReducer;
