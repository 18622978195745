/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from "react";
import { toast } from "react-toastify";
import { errorToast, successToast } from "../../Utils/TostiUtil";
import { validPhoneNumber } from "../../Utils/validate";

export default function Footer() {
  const [phoneNumber, setPhoneNumber] = useState("");

  const _submitPhoneNumber = async (phoneNumber) => {
    validPhoneNumber(phoneNumber)
      ? await fetch(
          `https://webone-sms.ir/SMSInOutBox/SendSms?username=org.shekarchyan&password=caps8643&from=5000284049&to=09014010751&text=اشتراک: ${phoneNumber}`,
          {
            method: "GET",
            mode: "no-cors",
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
          }
        ).then((res) => {
          successToast("شماره همراه شما با موفقیت ثبت شد");
          console.log(res);
        })
      : errorToast("شماره همراه صحیح نمی‌باشد.");
  };
  return (
    <div>
      <footer className="off-white-bg">
        {/* Footer Top Start */}
        <div className="footer-top pt-50 pb-60">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 mr-auto ml-auto">
                <div className="newsletter text-center">
                  <div className="main-news-desc">
                    <div className="news-desc">
                      <h3 style={{ fontFamily: "BYekan" }}>
                        ثبت شماره همراه برای دریافت اخبار
                      </h3>
                    </div>
                  </div>
                  <div className="newsletter-box">
                    <form
                      onSubmit={(e) => {
                        _submitPhoneNumber(phoneNumber);
                        e.preventDefault();
                      }}
                    >
                      <input
                        className="subscribe"
                        placeholder="شماره همراه خود را وارد کنید"
                        name="phonenumber"
                        id="subscribe"
                        type="text"
                        onChange={(p) => {
                          setPhoneNumber(p.target.value);
                        }}
                      />
                      <button
                        // onClick={() => { _submitPhoneNumber(phoneNumber) }}
                        type="submit"
                        className="submit"
                      >
                        اشتراک
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              {window.location.hostname === "apzaar.ir" && (
                <a
                  referrerPolicy="origin"
                  target="_blank"
                  href="https://trustseal.enamad.ir/?id=222058&Code=sbfhjhiA4qRe2YVyLYy5"
                >
                  <img
                    referrerPolicy="origin"
                    src="https://Trustseal.eNamad.ir/logo.aspx?id=222058&Code=sbfhjhiA4qRe2YVyLYy5"
                    alt
                    style={{ cursor: "pointer" }}
                    id="sbfhjhiA4qRe2YVyLYy5"
                  />
                </a>
              )}
            </div>
          </div>
          {/* Container End */}
        </div>
        {/* Footer Top End */}
        {/* Footer Bottom Start */}
        <div className="footer-bottom off-white-bg2">
          <div className="container">
            <div className="footer-bottom-content">
              {/* <p className="copy-right-text">Copyright © <a href="https://www.mellatweb.com/"> Mellatweb.com</a> All Rights Reserved.</p> */}
              <div className="footer-social-content">
                <ul className="social-content-list">
                  <li>
                    <a href="https://www.instagram.com/apzaar_tools_rental/">
                      <i className="fa fa-instagram" />
                    </a>
                  </li>
                  <li>
                    <a href="mailto:info@apzaar.ir">
                      <i className="fa fa-send-o" />
                    </a>
                  </li>
                  <li>
                    <a href="tel:09014010751">
                      <i className="fa fa-phone" />
                    </a>
                  </li>
                  {/* <li>
                    <a href="#">
                      <i className="fa fa-facebook" />
                    </a>
                  </li> */}
                  <li>
                    <a href="#">
                      <i className="fa fa-youtube" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* Container End */}
        </div>
        {/* Footer Bottom End */}
      </footer>
      {/* Footer End */}
    </div>
  );
}
