const ProductsData = [
  {
    id: "1",
    category: "electrical_tools",
    enName: "SawPerpendicular",
    name: "اره عمودبر 6دور صنعتی HP6028",
    brand: "HYUNDAI",
    model: "6028",
    price: { daily: 60000, delivery: 50000 },
    images: [
      { id: "1", source: "img/products/saw_perpendicular/1.jpg" },
      { id: "2", source: "img/products/saw_perpendicular/2.jpg" },
      { id: "3", source: "img/products/saw_perpendicular/3.jpg" },
      { id: "4", source: "img/products/saw_perpendicular/4.jpg" },
      { id: "5", source: "img/products/saw_perpendicular/5.jpg" },
    ],

    specs: [
      { id: "1", prop: "منبع تغذیه", value: "برق" },
      { id: "2", prop: "ابعاد", value: "25x23x9 سانتی متر" },
      { id: "3", prop: "وزن ", value: "1 کیلوگرم" },
      { id: "4", prop: "بازه توان", value: "500-1000 وات" },
      { id: "5", prop: "توان", value: "600 وات" },
      { id: "6", prop: "کیسه براده", value: "ندارد" },
      { id: "7", prop: "قفل سوییچ", value: "دارد" },
      { id: "8", prop: "بازه سرعت حرکت تیغه", value: "2500-3000 دور در دقیقه" },
      { id: "9", prop: "قابلیت فارسی‌بر", value: "دارد" },
      { id: "10", prop: "قابلیت برش با زاویه", value: "دارد" },
      { id: "11", prop: "سرعت حرکت آزاد", value: "3000" },
    ],
    details: `اره عمود بر یکی از ابزارهای ساده، حساس و درعین‌حال پرکاربرد در صنعت چوب  و فلز است که برای انجام برش های متنوع مستقیم و منحنی مورداستفاده قرارمیگیرد.
      قدرت موتور این اره عمود بر هیوندای این 600 وات است.
      دارای دیمر شش حالته جهت شروع نرم و آسان و کنترل سرعت میباشد.
      قابلیت تنظیم زاویه صفحه برش دارد.
      تجهیز شده به لیزر جهت تنظیم خط برش است.
      ظرفیت برش در چوب: 65 , فلز 8 میلی‌ متر است.
      از کیفیت ساخت بالایی برخوردار است.
      وزن این اره عمود بر 3 کیلوگرم می باشد.
      این محصول ساخت کشور کره است.`,
  },
  {
    id: "2",
    category: "electrical_tools",
    enName: "Miter Saw",
    name: "فارسی بر موتور تسمه ای 25cm کشویی رونیکس",
    brand: "RONIX",
    model: "5302",
    price: { daily: 150000, delivery: 80000 },
    images: [
      { id: "1", source: "img/products/miter_saw_ronix/1.jpg" },
      { id: "2", source: "img/products/miter_saw_ronix/2.jpg" },
      { id: "3", source: "img/products/miter_saw_ronix/3.jpg" },
      { id: "4", source: "img/products/miter_saw_ronix/4.jpg" },
      { id: "5", source: "img/products/miter_saw_ronix/5.jpg" },
      { id: "6", source: "img/products/miter_saw_ronix/6.jpg" },
    ],
    specs: [
      { id: "1", prop: "مدل", value: "5302" },
      { id: "2", prop: "توان", value: "2000 وات" },
      { id: "3", prop: "ولتاژ", value: "220 ولت" },
      { id: "4", prop: "فرکانس", value: "50 هرتز" },
      { id: "5", prop: "سرعت در حالت آزاد", value: "5300" },
      {
        id: "6",
        prop: "ابعاد برش در زاویه صفر بر 90 درجه",
        value: "300*70 میلیمتر",
      },
      {
        id: "7",
        prop: "ابعاد برش در زاویه 45 بر 90 درجه",
        value: "200*70 میلیمتر",
      },
      {
        id: "8",
        prop: "ابعاد برش در زاویه 0 بر 45 درجه",
        value: "300*42 میلیمتز",
      },
      {
        id: "9",
        prop: "ابعاد برش در زاویه 45 بر 45 درجه",
        value: "200*42 میلیمتر",
      },
    ],
    details: `✔ دارای قفل کن بازویی
    ✔ قابلیت انجام برش میزی سریع و برش بازویی
    ✔ مجهز به سیستم گونیای مدرج دو طرفه و گیره متحرک
    ✔ دارای تیغ اره الماسه TCG مخصوص برش MDF با قطر 255 میلیمتر
    ✔ استفاده از تسمه در سیستم انتقال قدرت به منظور کاهش صدا و لرزش و همچنین افزایش دقت برش
    ✔ مجهز به سیستم قفل کن پیشرفته و دقیق در زوایای برش میزی و بازویی
    ✔ مجهز به سیستم نور لیزری برای برش دقیق
    ✔ مجهز به صفحه عریض شونده جهت ایجاد سهولت برای برش های متوالی
    ✔ اره‌ای با نهایت دقت و کیفیت برش
    ✔ دارای گیره نگهدارنده ایمنی
    ✔ مجهز به کلید ضد غبار با کیفیت، دارای استاندارد CE اروپا مناسب جهت مصارف مداوم و صنعتی
    ✔ دارای سیستم کشویی دوبل بلبرینگی جهت انجام برش‌های دقیق با دامنه برش بالا
    ✔ مجهز به سیستم قفل کن پیشرفته و سریع میز برش در 9 زاویه کاربردی جهت انجام برش های بازویی
    ✔ مجهز به موتوری پر قدرت متشکل از آرمیچر و بالشتک صنعتی تولید شده توسط دستگاه های تمام اتوماتیک تحت استاندارد CE اروپا
    ✔ استفاده از قطعات تولید شده توسط ماشینهای CNC پیشرفته جهت ایجاد نهایت دقت حین انجام برشکاری در زوایا و حالات متفاوت متناسب با نیاز کاربران
    ✔ مجهز به کیسه غبارگیر`,
  },
  {
    id: "3",
    category: "electrical_tools",
    enName: "Angle Grinder",
    name: "فرز سنگبری 230 میلی متر 2350وات مدل 3212",
    brand: "RONIX",
    model: "3212",
    price: { daily: 80000, delivery: 50000 },
    images: [
      { id: "1", source: "img/products/angle_grinder_ronix/1.jpg" },
      { id: "2", source: "img/products/angle_grinder_ronix/2.jpg" },
      { id: "3", source: "img/products/angle_grinder_ronix/3.jpg" },
      { id: "4", source: "img/products/angle_grinder_ronix/4.jpg" },
      { id: "5", source: "img/products/angle_grinder_ronix/5.jpg" },
      { id: "6", source: "img/products/angle_grinder_ronix/6.jpg" },
    ],
    specs: [
      { id: "1", prop: "مدل", value: "3212" },
      { id: "2", prop: "توان", value: "2350 وات" },
      { id: "3", prop: "ولتاژ", value: "220 ولت" },
      { id: "4", prop: "فرکانس", value: "50 هرتز" },
      { id: "5", prop: "سرعت در حالت آزاد", value: "6000" },
      {
        id: "6",
        prop: "قطر صفحه برش",
        value: "230 میلی‌متر",
      },
      {
        id: "7",
        prop: "اندازه شفت",
        value: "M14",
      },
      {
        id: "8",
        prop: "وزن",
        value: "6 کیلوگرم",
      },
    ],
    details: `
      ✔ مجهز به موتور قدرتمند صنعتی با توان 2350 وات
      ✔ دارای دسته جانبی ضدلرزش طراحی شده توسط رونیکس جهت به حداقل رساندن خستگی کاربر هنگام فعالیت و افزایش دقت عملکرد محصول
      ✔ مجهز به سیستم گردش هوای داخلی پیشرفته
      ✔ دارای دسته اصلی گردان که سرعت کار در زوایای مختلف را تسریع می‌کند و راحتی و کنترل کاربر را بهبود می‌بخشد
      ✔ دارای بلبرینگ‌های ضد گردوغبار NSK که طول عمر موتور را تضمین کرده و عملکرد آن را روانتر می‌کند
      ✔ قابلیت تعویض سریع و آسان صفحه برش
      ✔ طراحی منحصربفرد در قسمت جاذغالی جهت تعویض سریع و آسان ذغال‌ها
      ✔ مجهز به سیستم قفل‌کن شفت پیشرفته جهت نصب آسان و ایمن صفحه برش
      ✔ دارای کلید ضد گردوغبار که باعث کاهش نفوذ گردوغبار به داخل فرز به ویژه در مصارف ساختمانی می شود
     `,
  },
  {
    id: "4",
    category: "electrical_tools",
    enName: "HammerDrill",
    name: "دریل 13 چکشی 600 وات رونیکس مدل 2211",
    brand: "RONIX",
    model: "2211",
    price: { daily: 60000, delivery: 50000 },
    images: [
      { id: "1", source: "img/products/hammer_drills/1.jpg" },
      { id: "2", source: "img/products/hammer_drills/2.jpg" },
      { id: "3", source: "img/products/hammer_drills/3.jpg" },
      { id: "4", source: "img/products/hammer_drills/4.jpg" },
      { id: "5", source: "img/products/hammer_drills/5.jpg" },
      { id: "6", source: "img/products/hammer_drills/6.jpg" },
      { id: "7", source: "img/products/hammer_drills/7.jpg" },
    ],
    specs: [
      { id: "1", prop: "مدل", value: "2211" },
      { id: "2", prop: "نوع سه نظام", value: "آچاری" },
      { id: "3", prop: "ظرفیت سه نظام", value: "	13 میلی‌متر" },
      { id: "4", prop: "توان", value: "600 وات" },
      { id: "5", prop: "فرکانس", value: "50 هرتز" },
      { id: "6", prop: "حداکثر ظرفیت سوراخکاری در چوب", value: "25 میلی‌متر" },
      { id: "7", prop: "حداکثر ظرفیت سوراخکاری درفلز", value: "13 میلی‌متر" },
      { id: "8", prop: "حداکثر ظرفیت سوراخکاری در بتن", value: "13 میلی‌متر" },
      { id: "9", prop: "سرعت در حالت آزاد", value: "صفر تا 3000 دور در دقیقه" },
      {
        id: "10",
        prop: "حداکثر تعداد ضربه در حالت آزاد",
        value: "46000 ضربه در دقیقه",
      },
      { id: "11", prop: "ولتاژ", value: "220 ولت" },
      { id: "12", prop: "وزن", value: "1.75" },
    ],
    details: `
    ✔مجهز به موتور کارآمد و قدرتمند 600 واتی برای بیشترین بهره‌وری در حالت عادی و چکشی
    ✔دارای وزن کم و ارگونومی مناسب جهت استفاده‌های طولانی مدت
    ✔دارای حداکثر تعداد ضربه در حالت چکشی برای نفوذ آسان در سطوح مختلف چوبی، فلزی و بتنی
    ✔دارای سه نظام آچاری 13 میلی‌متری تمام فلزی و مستحکم
    ✔مجهز به دسته جانبی ضد لرزش و گردان 360 درجه
    ✔مجهز به کلید الکترونیک دیمردار ضدغبار جهت تنظیم دقیق سرعت مته
    ✔با قابلیت چرخش مته به صورت چپگرد و راستگرد
    ✔مجهز به کلید الکترونیک چپگرد/راستگرد ضد غبار مطابق با استاندارد TUV
    `,
  },
  {
    id: "5",
    category: "electrical_tools",
    enName: "Demolition Hammer",
    name: "چکش تخریب رونیکس 6 کیلویی مدل 2807",
    brand: "RONIX",
    model: "2807",
    price: { daily: 80000, delivery: 50000 },
    images: [
      { id: "1", source: "img/products/demolition_hammer_ronix/1.jpg" },
      { id: "2", source: "img/products/demolition_hammer_ronix/2.jpg" },
      { id: "3", source: "img/products/demolition_hammer_ronix/3.jpg" },
      { id: "4", source: "img/products/demolition_hammer_ronix/4.jpg" },
      { id: "5", source: "img/products/demolition_hammer_ronix/5.jpg" },
      { id: "6", source: "img/products/demolition_hammer_ronix/6.jpg" },
      { id: "7", source: "img/products/demolition_hammer_ronix/7.jpg" },
    ],
    specs: [
      { id: "1", prop: "مدل", value: "2807" },
      { id: "2", prop: "توان", value: "900 وات" },
      { id: "3", prop: "ولتاژ", value: "220 ولت" },
      { id: "4", prop: "فرکانس", value: "50 هرتز" },
      { id: "5", prop: "تعداد ضربه", value: "2900 ضربه در دقیقه" },
      { id: "6", prop: "ضخامت قلم", value: "17 میلیمتر" },
      { id: "7", prop: "سیستم ابزارگیر", value: "شش گوش" },
      { id: "8", prop: "توان ضربه", value: "8 ژول" },
      { id: "9", prop: "وزن", value: "5.5" },
    ],
    details: `
    ✔تحت لیسانس GS, EMC و CE
    ✔مجهز به دسته جانبی گردان
    ✔مجهز به سیستم قلم گیر شش گوش HEX
    ✔دارای کیف BMC مقاوم در برابر ضربه
    ✔سبک و کم حجم جهت حداکثر کنترل حین عملیات تخریب
    ✔استفاده از دنده های با کیفیت جهت طول عمر بیشتر دستگاه
    ✔مجهز به اورینگ های ضد حرارت جهت تاثیر بهینه در عملکرد قسمت مکانیکی دستگاه
    ✔مجهز به کلید ضدغبار با کیفیت، دارای استاندارد CE اروپا مناسب جهت مصارف مداوم و صنعتی
    ✔مجهز به آرمیچر و بالشتک صنعتی تولید شده توسط دستگاه های تمام اتوماتیک تحت استاندارد CE اروپا
    ✔استفاده از بهترین آلیاژ مواد اولیه در تولید سیلندر، پیستون، ضربه زن و شاتون دستگاه جهت بالا بردن عمر کارکرد و اثر بخشی عملکرد تخریب
    `,
  },
  {
    id: "6",
    category: "electrical_tools",
    enName: "ElectricScrewdriver",
    name: "دریل پیچ گوشتی شارژی مکس 77140370",
    brand: "MAKS",
    model: "77140370",
    price: { daily: 50000, delivery: 50000 },
    images: [
      { id: "1", source: "img/products/electric_screwdriver/1.jpg" },
      { id: "2", source: "img/products/electric_screwdriver/2.jpg" },
      { id: "3", source: "img/products/electric_screwdriver/3.jpg" },
      { id: "4", source: "img/products/electric_screwdriver/4.jpg" },
      { id: "5", source: "img/products/electric_screwdriver/5.jpg" },
    ],
    specs: [
      { id: "1", prop: "ابعاد", value: "19.5x5x20 سانتی متر" },
      { id: "2", prop: "وزن", value: "0.920 کیلوگرم" },
      { id: "3", prop: "منبع تغذیه", value: "باتری" },
      { id: "4", prop: "قابلیت کنترل سرعت", value: "دارد" },
      { id: "5", prop: "سرعت گردش آزاد", value: "0-350 / 0-1300 دور بر دقیقه" },
      { id: "6", prop: "سیستم گردش", value: "راست گرد، چپ گرد" },
      { id: "7", prop: "قفل سوییچ", value: "ندارد" },
      { id: "8", prop: "مشخصات سه‌نظام", value: "خودکار 10 میلی‌متری" },
      { id: "9", prop: "مشخصات باتری", value: "12 ولت" },
    ],
    details: `
    ✔سرعت گردش آزاد: حالت اول 0-350 حالت دوم 0-1300 دور بر دقیقه
    ✔دارای کیف حمل
    ✔دارای 1 عدد باتری اضافه
    ✔دارای شارژر با طول کابل 80 سانتی‌متر
    `,
  },
  {
    id: "7",
    category: "ladders",
    enName: "SlidingLadder",
    name: "نردبان 9 متری سه تیکه مدل پرشین راد پله",
    brand: "آیرون",
    model: "aa",
    price: { daily: 250000, nextDays: 100000, hourly: 150000, nextHours: 50000, delivery: 0 },
    images: [
      { id: "1", source: "img/products/sliding_ladder/1.jpg" },
      { id: "2", source: "img/products/sliding_ladder/2.jpg" },
      { id: "3", source: "img/products/sliding_ladder/3.jpg" },
      { id: "4", source: "img/products/sliding_ladder/4.jpg" },
    ],
    specs: [
      { id: "1", prop: "تعداد پله", value: "34" },
      { id: "2", prop: "وزن", value: "16 کیلوگرم" },
      { id: "3", prop: "وزن قابل تحمل", value: "۱۱۰ کیلوگرم" },
    ],
    details: `
    ✔متراژ کشو 3/3 
    ✔از آلومینیوم درجه یک ساخته شده‌اند
    ✔وزن 19/200 کیلوگرم

    `,
  },
  {
    id: "8",
    category: "ladders",
    enName: "SlidingLadder",
    name: "نردبان 5 متری مفصل دار هارمونی (16 پله 4 تکه)",
    brand: "آیرون",
    model: "aa",
    price: { daily: 250000, nextDays: 100000, hourly: 150000, nextHours: 50000, delivery: 0 },
    images: [
      { id: "1", source: "img/products/articulated_ladder/1.jpg" },
      { id: "2", source: "img/products/articulated_ladder/2.jpg" },
    ],
    specs: [
      { id: "1", prop: "تعداد پله", value: "16" },
      { id: "2", prop: "کاربرد", value: "چند منظوره" },
      { id: "3", prop: "وزن قابل تحمل", value: "136 کیلوگرم" },
      { id: "4", prop: "عرض پله", value: "3 سانتیمتر" },
      { id: "5", prop: "فاصله بین پله ها", value: "28 سانتیمتر" },
      { id: "6", prop: "نوع پله", value: "آلمینیوم" },
      {
        id: "7",
        prop: "ارتفاع کامل نردبان در حالت باز",
        value: "230 سانتی متر",
      },
      {
        id: "8",
        prop: "ارتفاع کامل نردبان در حالت بسته",
        value: "130 سانتی متر",
      },
      { id: "9", prop: "ارتفاع دسترسی", value: "485 سانتی متر" },
    ],
    details: `نردبان هارمونی مدل 4 تکه 16 پله یک نردبان چند منظوره میباشد که تا 136 کیلو گرم وزن را تحمل میکند و ارتفاع این نردبان در حالت بسته تنها 130 سانتی متر میباشد که این ارتفاع کم باعث اشغال فضای کمتر برای نگهداری و سهولت در حمل و نقل این نردبان می شود

    نردبان های سوپر هارمونی با توجه به نحوه ی باز شدن دارای کاربرد های وسیعی می باشند، این نوع از نردبان ها را با توجه به نوع ساخت می توان به راحتی در محیط های کوچک حمل و یا انبار کرد.
    
     نردبانهای سوپر هارمونی دارای بدنه 8 سانتی متری از نوع آلومینیوم آج دار با پله 2.5 سانتیمتری و مفصل های تاشو این امکان را به شما می دهند که به راحتی به حالت های از پیش تعیین شده تغییر شکل دهند از این رو این نردبان ها را می توان برای موارد زیادی استفاده کرد.
    
    ارتفاع آنها 3,4,5 متری در 4 تکه می باشد.
    این نردبان ها دارای 2 پایه 1 متری برای ایجاد سطح مقطع بیشتر و ممانعت از لیز خوردن های احتمالی  است.
    یکی از خطرات بالقوه در زمان کار با نردبان ، احتمال باز شدن زاویه بین دو نردبان و در نتیجه سقوط کاربر می باشد.
     جهت بالا بردن امنیت کاربر در زمان استفاده ، در زیر هرکدام از پایه های نردبان ، یک پایه عریض تر به طول ۶۰ سانتیمتر نصب می شود که ترمزهای پلاستیکی جهت بالا بردن میزان اصطکاک سطحی در زیر آن تعبیه شده اند.
    وظیفه اصلی این قسمت، جلوگیری از سر خوردگی و باز شدن نردبان در هنگام کار می باشد.
    ضمن اینکه این قطعه تعادل نردبان را نیز حفظ می کند تا از چپ و راست سقوط نکند،ما خیلی زمانها به تنهایی کار انجام می دهیم و کسی کمکان نمی کند به همین علت، حسن این نردمان به کارمان می آید.`,
  },
  {
    id: "9",
    category: "electrical_tools",
    enName: "Demolition Hammer",
    name: "بتن کن نک مدل NEK 1232 CH",
    brand: "NEK",
    model: "1232",
    price: { daily: 70000, delivery: 50000 },
    images: [
      { id: "1", source: "img/products/demolition_hammer_nek1232/1.jpg" },
      { id: "2", source: "img/products/demolition_hammer_nek1232/2.jpg" },
    ],
    specs: [
      { id: "1", prop: "قدرت", value: "1250 وات" },
      { id: "2", prop: "سرعت (دور در دقیقه)", value: "750" },
      { id: "3", prop: "انرژی ضربه ای (ژول)", value: "4.5 ژول" },
      { id: "4", prop: "ظرفیت سوراخکاری در بتن", value: "32 میلیمتر" },
      { id: "5", prop: "ظرفیت سوراخکاری در چوب", value: "40 میلیمتر" },
      { id: "6", prop: "تعداد ضربه", value: "3200 ضربه در دقیقه" },
      { id: "7", prop: "نوع مته یا قلم", value: "چهار شیار" },
    ],
    details: `
    بتن کن نک مدل NEK 1232 CH با استفاده از حالت پیستونی باعث ایجاد ضربه های قوی جهت سوراخکاری و ایجاد حفره در مصالح استفاده می شود. 
    بتن کن ها براساس نوع مته به دو دسته چهار شیار و پنج شیار تقسیم می شوند. بتن کن مدل NEK 1232 CH از نوع چهار شیار بوده و قابلیت سوراخکاری تا قطر 32 میلیمتر در بتن و مصالح ساختمانی را دارد. این دستگاه دارای طراحی ارگونومیک و ضد لرزش بوده و مانع از خستگی کاربر در طولانی مدت می شود. بتن کن نک مدل NEK 1232 CH در گروه ابزارآلات برقی قرار می گیرد و با یک موتور پرقدرت 1250 واتی کار می کند.
    سرعت گردش مته 750 دور در دقیقه می باشد. تعداد ضرباتی که این بتن کن می تواند به سطح وارد کند 3200 ضربه در دقیقه می باشد. در کنار این محصول متعلقاتی شامل 2 عدد قلم 3 عدد مته و سه نظام و رابط نیز وجود دارد. نکته مهم اینکه در عملیات تخریب با استفاده از بتن کن ها باید به صورت مقطعی مورد استفاده قرار بگیرد زیرا بتن کن ها برای عملیات سوراخکاری ساخته می شوند.
    `,
  },
  {
    id: "10",
    category: "electrical_tools",
    enName: "Demolition Hammer",
    name: "چکش تخریب 34 کیلویی ان ای سی مدل G90",
    brand: "NEC",
    model: "G90",
    price: { daily: 150000, delivery: 80000 },
    images: [
      { id: "1", source: "img/products/demolition_hammer_nec-g90/1.jpg" },
    ],
    specs: [
      { id: "1", prop: "قدرت", value: "2000 وات" },
      { id: "2", prop: "قدرت ضربه", value: "60 ژول" },
      { id: "3", prop: "تعداد ضربه در حالت آزاد", value: "1300 ضربه در دقیقه" },
      { id: "4", prop: "سه نظام", value: "HEX" },
      { id: "5", prop: "قلم‌ها", value: "40 و 60 سانتی متری" },
    ],
    details: `
    چکش تخریب فوق سنگین 34 کیلویی ان ای سی مدل G90 ساخت کشور چین  و دارای موتور پر قدرت 2000 وات است که شدت هر ضربه به 60 ژول می رسد و 1300 ضربه در دقیقه سرعت ضربه زنی آن می باشد. 
    طراحی موتور ، آرمیچر و سیلندر این چکش فوق سنگین بر اساس سیستم چکش تخریب شرکت دیوالت آلمان می باشد به این معنا که قابلیت تعویض این قطعات با برند دیوالت وجود دارد و در دنده های آن از با کیفیت ترین آلیاژ ها استفاده شده است. چکش تخریب فوق سنگین 34 کیلویی ان ای سی مدل G90  دارای سیلندر با حجم بالاست به طوری که قدرت ضربه ، مستقیم از سیلندر به قلم منتقل می شود.
    چکش 34 کیلویی NEC دارای قلم های 40 و 60 سانتیمتری بوده و در تمامی آب و هواها امکان استفاده از آن وجود  دارد.
    `,
  },
  {
    id: "11",
    category: "electrical_tools",
    enName: "Demolition Hammer",
    name: "چکش تخریب آنکور 11 کیلویی مدل DH8",
    brand: "ANCHOR",
    model: "DH8",
    price: { daily: 150000, delivery: 80000 },
    images: [
      { id: "1", source: "img/products/demolition_hammer_anchor_dh8/1.jpg" },
    ],
    specs: [
      { id: "1", prop: "توان", value: "1500 وات" },
      { id: "2", prop: "تعداد ضربه در حالت آزاد", value: "1900 ضربه در دقیقه" },
      { id: "3", prop: "سه نظام", value: "5 شیار" },
      { id: "4", prop: "وزن", value: "11 کیلو گرم" },
    ],
    details: `
    چکش تخریب آنکور مدل DH8 برخوردار از قدرت 1500 وات، توانایی 900 تا 1900 ضربه در دقیقه و همچنین سیستم کنترل سرعت (دیمر) است. این چکش تخریب با وارد کردن ضربات منظم و قدرتمند قادر است
     سطوح ساخته شده از بتن، سیمان، گچ و غیره را تخریب کند‏. چکش تخریب آنکور مدل DH8 
    دارای وزن 11 کیلوگرم می‌باشد، منبع تغذیه این ابزار برق شهری 220 ولت است و قلم گیر آن از نوع 5 شیار است.
    `,
  },
  {
    id: "12",
    category: "electrical_tools",
    enName: "Demolition Hammer",
    name: "پیکور تخریب 30 کیلویی ان ای سی NEC مدل 2485",
    brand: "NEC",
    model: "2485",
    price: { daily: 150000, delivery: 80000 },
    images: [
      { id: "1", source: "img/products/demolition_hammer_nec_2485/1.jpg" },
    ],
    specs: [
      { id: "1", prop: "توان", value: "2100 وات" },
      { id: "2", prop: "قدرت ضربه", value: "85 ژول" },
      { id: "3", prop: "تعداد ضربه در حالت آزاد", value: "1600 ضربه در دقیقه" },
      { id: "4", prop: "سه نظام", value: "6 گوش" },
      { id: "5", prop: "وزن", value: "30 کیلو گرم" },
    ],
    details: `
    ✔پیکور دو دسته SLIM DESIGN با وزن کم و قدرت زیاد
    ✔آرمیچر قفل شده جهت جلوگیری از ورود گرد و غبار
    ✔پیکور تک کاره با موتور قدرتمند 2100 وات
    ✔دارای دسته ضد عرق با روکش پلاستیکی
    ✔تعداد 1600 ضربه در دقیقه
    ✔قدرت هر ضربه 85 ژول
    ✔تعویض آسان ذغال
    `,
  },
  {
    id: "13",
    category: "electrical_tools",
    enName: "Demolition Hammer",
    name: "دریل بتن کن 5 شیار نک مدل NEK 1038 CH",
    brand: "NEK",
    model: "1038",
    price: { daily: 70000, delivery: 50000 },
    images: [
      { id: "1", source: "img/products/demolition_hammer_nek_1038/1.jpg" },
      { id: "2", source: "img/products/demolition_hammer_nek_1038/2.jpg" },
      { id: "3", source: "img/products/demolition_hammer_nek_1038/3.jpg" },
      { id: "4", source: "img/products/demolition_hammer_nek_1038/4.jpg" },
    ],
    specs: [
      { id: "1", prop: "توان", value: "1050 وات" },
      { id: "2", prop: "قدرت ضربه", value: "8 ژول" },
      { id: "3", prop: "تعداد ضربه در حالت آزاد", value: "3000 ضربه در دقیقه" },
      { id: "4", prop: " دور در حالت آزاد", value: "550 دور در دقیقه" },
      { id: "5", prop: "سه نظام", value: "5 شیار" },
      { id: "6", prop: "وزن", value: "6.5 کیلو گرم" },
    ],
    details: `
    ✔دارا بودن موتور پر قدرت
    ✔طراحی وساخت تمام اجزای دستگاه با کیفیت بسیار بالا
    ✔استفاده از آلیاژهای عالی در ساخت هر قسمت
    ✔طراحی به بهترین حالت ارگونومیک برای کار راحت و بی ‌خطر و حفظ سلامت کاربر
    ✔دارای کلاچ ایمنی و قرت بسیار بالا در هنگام ضربات، با قدرت 8 ژول
    ✔استفاده از مته‌ گیر با کیفیت 5 شیار SDS max
    `,
  },
  {
    id: "14",
    category: "electrical_tools",
    enName: "Demolition Hammer",
    name: "دریل بتن کن پی ام مدل 11253",
    brand: "Anchor",
    model: "11253",
    price: { daily: 70000, delivery: 50000 },
    images: [
      { id: "1", source: "img/products/demolition_hammer_anchor_11253/1.jpg" },
      { id: "2", source: "img/products/demolition_hammer_anchor_11253/2.jpg" },
    ],
    specs: [
      { id: "1", prop: "توان", value: "800 وات" },
      { id: "2", prop: "تعداد ضربه در حالت آزاد", value: "4000 ضربه در دقیقه" },
      { id: "3", prop: " دور در حالت آزاد", value: "900 دور در دقیقه" },
      { id: "4", prop: "سه نظام", value: "4 شیار" },
      { id: "5", prop: "وزن", value: "2.9 کیلو گرم" },
    ],
    details: `
    ✔کیفیت ساخت بالا
    ✔توان ورودی: 800 وات
    ✔قابلیت کنترل سرعت
    ✔قفل سوئیچ
    وزن دستگاه: ✔2.9 kg
    ✔چپ و راست ، گازی ، سه کاره
    ✔نوع قلم: 4 شیار
    ✔دریل P.M 11254 از شرکت آنکور نیروی خود را از طریق برق شهری تامین می‌کند. بیشینه سرعت دوران این دریل 900 دور بر دقیقه است. این دریل توانایی چرخش در دو جهت را دارد . این دریل با داشتن قابلیت چکشی و توان 800 دریلی مناسب برای مصارف خانگی و تجاری است.
    `,
  },
  {
    id: "15",
    category: "elevators",
    enName: "elevators",
    name: "بالابر نفربر هیدرولیکی تک دکله چرخ کارگاهی",
    brand: "balansanat",
    model: "EHS",
    price: { daily: 400000, delivery: 100000 },
    images: [
      { id: "1", source: "img/products/elevators/1.jpg" },
      { id: "2", source: "img/products/elevators/2.jpg" },
      { id: "3", source: "img/products/elevators/3.jpg" },
    ],
    details: `
    ✔بالابر تک دکله چرخ کارگاهی مخصوص نفر با ارتفاع کاربردی تا 11 متر
    ✔سامانه قدرت هیدرولیکی با برق تک فاز شهری یا باتری
    ✔سامانه جلوگیری از بار غیر مجاز
    ✔آمیزه ای از فولاد و آلومینیوم و استفاده از قطعات استاندارد در ساخت
    ✔بالابر چرخ کارگاهی شامل یک دکل تلسکوپی متشکل از چند ریل آلومینیومی
    ✔جک های استقرار دستگاه در هر شرایطی سطح اتکاء گسترده و مطمئنی را فراهم می کنند.
    ✔پیش بینی های ایمنی لازم در دستگاه و قرار دادن کنترل کننده های الکتریکی در سیستم بازوهای جک استقرار، ضریب ایمنی استفاده از بالابر را افزایش داده است.
    ✔در صورت سفارش، بالابر می تواند با کارگیری کلیه قطعات الکتریکی و هیدرولیکی از نـوع ضد انفجار (EX) با درجه حفاظت بالا ارائه گردد.
    ✔دارای تجهیزات ویژه بارگیری و کاهش ارتفاع
    ✔دارای سکوی آهنی یا آلومینیومی با سفارش مشتری
    ✔کل دستگاه می تواند به سرعت توسط یک نفر برپا شود.
    ✔فشرده ، کم حجم و سبک
    ✔کم هزینه و بادوام
    `,
  },
];

export { ProductsData };
