import React from "react";
import { useState } from "react";

export default function SpecTable(props) {
  const { specs ,name} = props;

  return (
    <div>
      <table className="specs-table">
        <tbody>
          <th colspan="3"> مشخصات فنی: {name}</th>
          {specs.map((specs, index) => {
            const { id, prop, value } = specs;
            return (
              <tr key={id}>
                <td>{id}</td>
                <td>{prop}</td>
                <td>{value}</td>
              </tr>
            );
          })}
          {/* {renderTableData()} */}
        </tbody>
      </table>
    </div>
  );
}
