
import axios from 'axios';
import { actionSetUser } from '../redux/actions/user_actions';
import { errorToast, successToast } from '../Utils/TostiUtil';
import { baseUrl } from './../config/config';
import { store } from './../redux/Store';
import httpService, { httpConfig } from '../services/httpService';
const registerUser = async ({
    fullName,
    mobile,
    email,
    address,
    passWord
}) => {
    axios.defaults.headers.post["Content-Type"] = "application/json";
    return await httpService.post(`${baseUrl}users/register`, {
        fullName: fullName,
        mobile: mobile,
        email: email,
        address: address,
        passWord: passWord,
    }).then(res => {
        console.log(res, 'res')
        if (res.status === 200) {
            successToast('ثبت نام با موقیت انجام شد')
            return 'success'
        } else {
            errorToast('خطای ناشناخته')
        }
    }).catch(err => {
        errorToast(err)
    })
}


const loginUser = async (mobile, passWord) => {
    axios.defaults.headers.post["Content-Type"] = "application/json";
    return await httpService.post(`${baseUrl}users/login`, {
        mobile: mobile,
        passWord: passWord,
    }).then(res => {
        console.log(res.data);
        if (res.status === 200) {
            successToast('با موفقیت وارد شدید')
            const { token, userId, fullName, address, email, mobile } = res.data
            setStorageUser({ token, userId, fullName, address, email, mobile })
            return 'success'
        } else {
            errorToast('خطای ناشناخته')
        }
    }).catch(err => {
        errorToast(err, 'err')
    })
}


const setStorageUser = async ({
    token, userId, fullName, address, email, mobile }) => {
    console.log(userId, fullName, token, 'hhh');
    store.dispatch(actionSetUser({
        token, userId, fullName, address, email, mobile
    }))
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

export {
    registerUser,
    loginUser
}