const validEmail = (email) => {
  var regexmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regexmail.test(email);
}
const validPhoneNumber = (mobile) => {
  var regexphonenumber = /^09[0-9]{9}$/
  return regexphonenumber.test(mobile)
}

export {
  validEmail,
  validPhoneNumber
}
