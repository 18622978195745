import React from "react";
import { Link } from "react-router-dom";

export default function AboutUs() {
  return (
    <div>
      <div className="breadcrumb-area ptb-60 ptb-sm-30">
        <div className="container">
          <div className="breadcrumb">
            <ul>
              <li>
                <Link to="/">خانه</Link>
              </li>
              <li className="active">
                <a>درباره ما</a>
              </li>
            </ul>
          </div>
        </div>
        {/* Container End */}
      </div>
      {/* Breadcrumb End */}
      {/* About Main Area Start */}
      <div className="about-main-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-12">
              <div className="about-content text-right">
                <h3 style={{ fontFamily: "BYekan" }}>درباره ما</h3>
                <p style={{ fontFamily: "BYekan",textAlign:'justify' }}>
                  سه سال پس از شروع به کار پلکان که سامانه خدمات اجاره ی نردبان
                  های بلند و صنعتی است، اپزار متولد شد. بواسطه بالا بودن قیمت
                  ابزارهای صنعتی از یک سو و عدم نیاز مستمر به استفاده ی ابزار،
                  وجود کسب و کار اجاره ابزار را بر همگان روشن می کند. از دیگر سو
                  مشتریان پلکان بارها متقاضی ابزارهای دیگر برای انجام کار خود
                  بودند که پلکان از ارائه آن خدمت محروم بوده است. با این تاریخچه
                  و از ابتدای سال 1400 اپزار کار خود را آغاز نموده است. به
                  پشتوانه ی تجارب پلکان، اپزار به روشهای متنوع زمانی و با شبکه
                  گسترده تحویل، ابزار را در محل مشتری تحویل می دهد. برای سفارش
                  اپزار از طریق وب سایت اقدام نمایید یا با شماره تلفن
                  09014010751 تماس حاصل نمایید.
                </p>{" "}
                <br />
                <ul className="mt-20 about-content-list">
                  <li>
                    <a href="#">حمل و نقل رایگان</a>
                  </li>
                  <li>
                    <a href="#">تنوع خدمات بصورت ساعتی و روزانه</a>
                  </li>
                  <li>
                    <a href="#">تنوع ساعت خدمات قابل ارائه</a>
                  </li>
                  <li>
                    <a href="#">صدور بیمه حادثه</a>
                  </li>
                </ul>
              </div>
            </div>
            <div style={{ marginTop: 20 }} className="col-lg-7 col-md-12">
              <div className="about-img">
                <img
                  className="img"
                  src="img/aboutUs/aboutUs.png"
                  alt="about-us"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About Main Area End */}
      {/* Our Mission Start */}
      {/* <div className="about-bottom pt-50 pb-60">
        <div className="container">
          <div className="row ">
            <div className="col-lg-6 col-md-6 text-right">
              <div className="ht-single-about">
                <h3 style={{ fontFamily: "BYekan" }}>کارهای ما</h3>
                <div className="ht-about-work ">
                  <span>1</span>
                  <div className="ht-work-text">
                    <h5 style={{ fontFamily: "BYekan" }}>
                      <a href="#">یک یک یک یک یک</a>
                    </h5>
                    <p style={{ fontFamily: "BYekan" }}>یک یک یکی کیکی کی یک</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="ht-single-about pb-sm-40 text-right">
                <h3 style={{ fontFamily: "BYekan" }}>تجربیات ما</h3>
                <h5 style={{ fontFamily: "BYekan" }}>
                  {" "}
                  تجربیاتتجربیاتتجربیاتتجربیات تجربیات{" "}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* Our Mission End */}
    </div>
  );
}
