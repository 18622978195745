import React from 'react'
import { Redirect, Route, Switch, useLocation, useParams } from 'react-router'
import NotFound from './Components/Common/NotFound'
import MainLayout from './LayOut/MainLayout'
import AboutUs from './Screens/AboutUs'
import Account from './Screens/Account'
import ContactUs from './Screens/ContactUs'
import Login from './Screens/Login'
import Main from './Screens/Main'
import Product from './Screens/Product'
import Products from './Screens/Products'
import Register from './Screens/Register'

export default function Apzaar() {
    const params = useLocation()
    return (
        <MainLayout>
            <Switch>
                <Route path='/account' component={Account} />
                <Route path='/register' component={Register} />
                <Route path='/login' component={Login} />
                <Route path='/contactus' component={ContactUs} />
                <Route path='/aboutus' component={AboutUs} />
                <Route path='/product' render={() =>
                    params.pathname === "/product" && params.state ? < Product params={params} /> : <Redirect to="/products" />
                } />
                <Route path='/products' component={Products} />
                <Route path='/' exact component={Main} />
                <Route path='*' exact component={NotFound} />
            </Switch>
        </MainLayout>
    )
}
