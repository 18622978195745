import React from "react";
import { numberWithCommas } from "./../../../Utils/index";
import { Link } from 'react-router-dom';

export default function OrderSection(props) {
  const { items, states } = props;
  const { daily, hourly, nextHours, nextDays, delivery } = items.price;
  const {
    isDaily,
    setIsDaily,
    orderDaysOrHours,
    setOrderDaysOrHours,
    deliveryReq,
    setDeliveryReq,
    _addToBasket,
    total,
  } = states;
  return (
    <div className="order-section">
      {/* Thumbnail Description Start */}

      <div className="thubnail-desc text-right">
        {/* <div className="rating-summary fix mtb-10">
                  <div className="rating f-right">
                    <i className="fa fa-star" />
                    <i className="fa fa-star" />
                    <i className="fa fa-star-o" />
                    <i className="fa fa-star-o" />
                    <i className="fa fa-star-o" />
                  </div>
                  <div className="rating-feedback f-right">
                    <a href="#">(1 نظر)</a>
                    <a href="#">نظر خود را اضافه کنید</a>
                  </div>
                </div> */}
        <div className="box-quantity">
          <div>
            <p>
              {daily && (
                <span
                  style={{ fontFamily: "BYekan" }}
                  className="price"
                >{`اجاره روزانه ${numberWithCommas(daily)} تومان`}</span>
              )}

              {hourly && (
                <span
                  style={{ fontFamily: "BYekan" }}
                  className="price"
                >{`، اجاره ساعتی ${numberWithCommas(hourly)} تومان`}</span>
              )}
              {nextHours && (
                <span
                  style={{ fontFamily: "BYekan" }}
                  className="price"
                >{`،اجاره ساعتهای بعد ${numberWithCommas(nextHours)} تومان`}</span>
              )}
              {nextDays && (
                <span
                  style={{ fontFamily: "BYekan" }}
                  className="price"
                >{`، اجاره روزهای بعد ${numberWithCommas(nextDays)} تومان`}</span>
              )}
              {delivery !== 0 && (
                <span
                  style={{ fontFamily: "BYekan" }}
                  className="price"
                >{`، هزینه حمل ${numberWithCommas(delivery)} تومان`}</span>
              )}
            </p>
          </div>
          <form className="oder-input" action="#">
            {hourly && daily && (
              <div
                style={{ marginLeft: "10px" }}
                className="btn-group"
                role="group"
                aria-label="Basic example"
              >
                <button
                  style={{ backgroundColor: isDaily ? "#f1ac06" : "" }}
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    setIsDaily(true);
                  }}
                >
                  روزانه
                </button>
                <button
                  style={{ backgroundColor: !isDaily ? "#f1ac06" : "" }}
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    setIsDaily(false);
                  }}
                >
                  ساعتی
                </button>
              </div>
            )}

            <label>
              انتخاب تعداد {!isDaily ? "ساعت" : "روز"}
              <input
                className="number"
                id="numeric"
                type="number"
                min={1}
                value={orderDaysOrHours}
                defaultValue={1}
                onChange={(a) => [setOrderDaysOrHours(a.target.value)]}
              />
            </label>
            {delivery === 0 ? (
              <label style={{ justifyContent: "center", marginRight: 20 }}>
                ارسال این ابزار رایگان می‌باشد
              </label>
            ) : (
              <label style={{ justifyContent: "center", marginRight: 20 }}>
                ما برایتان ارسال کنیم؟
                <input
                  type="checkbox"
                  style={{ height: 15 }}
                  defaultChecked={deliveryReq}
                  onChange={() => setDeliveryReq(!deliveryReq)}
                />
              </label>
            )}

          </form>
        </div>
        {orderDaysOrHours > 0 ? (
          <Link
            className="add-cart mb-1"
            to="/"
            onClick={() => {
              _addToBasket();
            }}
          >
            ثبت سفارش اجاره برای {orderDaysOrHours}{" "}
            {isDaily ? "روز" : "ساعت"}؛ جمعا {numberWithCommas(total)} تومان
          </Link>
        ) : null}
      </div>
      {/* Thumbnail Description End */}
    </div>
  );
}
