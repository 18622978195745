import React from "react";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { actionSetShopBasket } from "../../redux/actions/shop_basket_actions";
import { store } from "./../../redux/Store";

export default function SingleProductList(props) {
  const { items } = props;
  const basket = useSelector((state) => state.shopBasketReducer.basket);

  const _addToBasket = (items) => {
    const basketC = [...basket];
    basketC.push(items);
    store.dispatch(actionSetShopBasket(basketC));
  };

  return (
    <div>
      {/* Single Product Start */}
      <div className="single-product ">
        {/* Product Image Start */}
        <div className="pro-img">
          <NavLink
            to={{
              pathname: "/product",
              state: { items },
            }}
          >
            <img
              className="primary-img"
              src={items.images[0].source}
              alt="single-product"
            />
            <img
              className="secondary-img"
              src={items.images[0].source}
              alt="single-product"
            />
          </NavLink>
        </div>
        {/* Product Image End */}
        {/* Product Content Start */}
        <div>
          {/* <div className="product-rating">
            <i className="fa fa-star" />
            <i className="fa fa-star" />
            <i className="fa fa-star" />
            <i className="fa fa-star" />
            <i className="fa fa-star" />
          </div> */}
          <h4>
            <NavLink
              to={{
                pathname: "/product",
                state: { items },
              }}
              style={{ fontFamily: "BYekan" }}
            >
              {items.name}
            </NavLink>
          </h4>
          <p>
            <span
              style={{ fontFamily: "BYekan", marginTop: 20 }}
              className="price"
            >{`اجاره روزانه ${items.price.daily} تومان`}</span>
          </p>
          <p
            style={{
              textAlign: "right",
              fontFamily: "BYekan",
              textAlign: "justify",
              padding:"10px 10px"
            }}
          >
            {items.details}
          </p>
          <p style={{ textAlign: "right", fontWeight: "bold" }}>
            تصویر ابزار نمونه است و ممکن است ابزار ارسالی از مارک دیگری باشد
            بدیهی ویژگی های فنی ابزار مورد درخواست شما را دارا می‌باشد
          </p>
        </div>
        {/* Product Content End */}
      </div>
      {/* Single Product Start */}
    </div>
  );
}
