/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { loginUser } from '../services/user_services';
import { useHistory } from 'react-router-dom';

export default function Login() {
    const history = useHistory()
    const [mobile, setMobile] = useState("");
    const [passWord, setPassWord] = useState("");

    const _submitOrder = async () => {
        console.log(mobile, passWord,'aaaa');
        await loginUser(mobile, passWord).then(res => {
            history.replace('/')
        })
    }

    return (
        <div>
            {/* Breadcrumb Start */}
            <div className="breadcrumb-area ptb-60 ptb-sm-30">
                <div className="container">
                    <div className="breadcrumb">
                        <ul>
                            <li><Link to="/">خانه</Link></li>
                            <li className="active"><a >ورود</a></li>
                        </ul>
                    </div>
                </div>
                {/* Container End */}
            </div>
            {/* Breadcrumb End */}
            {/* LogIn Page Start */}
            <div style={{ direction: "rtl" }} className="register-account pb-60">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            {/* <div className="register-title">
                <h4
                  style={{ fontFamily: "BYekan", textAlign: "center" }}
                  className="mb-10"
                >
                  مبلغ قابل پرداخت: {numberWithCommas(sum)}
                </h4>
                <h3
                  style={{ fontFamily: "BYekan", textAlign: "right" }}
                  className="mb-10"
                >
                  ورود اطلاعات کاربر
                </h3>
              </div> */}
                        </div>
                    </div>
                    {/* Row End */}
                    <div className="row">
                        <div className="col-sm-12">
                            <form
                                onSubmit={(event) => event.preventDefault()}
                                className="form-horizontal"
                                action="#"
                            >
                                <fieldset>
                                    <legend style={{ fontFamily: "BYekan", textAlign: "right" }}>
                                        فرم اطلاعات شخصی
                                    </legend>
                                    <div className="form-group">
                                        <div style={{ marginTop: 10 }} className="col-sm-12">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="l-name"
                                                placeholder="شماره همراه"
                                                onChange={(p) => {
                                                    setMobile(p.target.value);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div style={{ marginTop: 10 }} className="col-sm-12">
                                            <input
                                                type="password"
                                                className="form-control"
                                                id="number"
                                                placeholder="رمز عبور"
                                                onChange={(p) => {
                                                    setPassWord(p.target.value);
                                                }}
                                            />
                                        </div>
                                        <label className="control-label col-sm-12 mt-10"><NavLink to="/register">رمز عبور را فراموش کردید؟</NavLink></label>
                                        <label className="control-label col-sm-12"><NavLink to="/register">کاربر جدید هستید؟</NavLink></label>

                                    </div>
                                    <div className="newsletter-btn">
                                        <a
                                            onClick={() => {
                                                _submitOrder();
                                            }}
                                        >
                                            ورود
                                        </a>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                    {/* Row End */}
                </div>
                {/* Container End */}
            </div>
            {/* Register Account End */}
            {/* LogIn Page End */}
        </div>

    )
}
