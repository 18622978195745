import React, { useState } from "react";
import ReactModal from "react-modal";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { actionSetGreeting } from "../../redux/actions/greeting_actions";
import store from "../../redux/Store";
import { errorToast, successToast } from "../../Utils/TostiUtil";
import { validPhoneNumber } from "../../Utils/validate";

export default function GreetingModal(props) {
  const [phoneNumber, setPhoneNumber] = useState("");
  const modalVisible = useSelector((state) => state.greetingReducer.greeting);

  const _submitPhoneNumber = async (phoneNumber) => {
    validPhoneNumber(phoneNumber)
      ? await fetch(
        `https://webone-sms.ir/SMSInOutBox/SendSms?username=org.shekarchyan&password=caps8643&from=5000284049&to=09014010751&text=اشتراک: ${phoneNumber}`,
        {
          method: "GET",
          mode: "no-cors",
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      ).then((res) => {
        localStorage.setItem("phoneNumber", phoneNumber)
        successToast("شماره همراه شما با موفقیت ثبت شد");
        store.dispatch(actionSetGreeting(false));
        console.log(res);
      })
      : errorToast("شماره همراه صحیح نمی‌باشد.");
  };
  const customStyles = {
    content: {
      height: "45%",
      width: "70%",
      marginRight: "auto",
      marginLeft: "auto",
      marginBottom: "auto",
    },
  };

  return (
    <div >
      <ReactModal
        isOpen={modalVisible}
        // onAfterOpen={afterOpenModal}
        // onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div>
          <div className="row pt-20 pl-10 pr-10">
            <a className="fa fa-close close-btn"
              onClick={() => {
                store.dispatch(actionSetGreeting(false));
              }}
              style={{
                position: "absolute",
                top: 5,
                right: 10,
                color: "#FFFFFF",
              }}

            />
            <div className="newsletter text-center">
              <div className="main-news-desc">
                <div className="news-desc">
                  <h5 className="txt-modal" style={{ fontFamily: "BYekan" }}>
                    با ورود شماره همراه خود، مشترک سامانه اپزار خواهید شد و از
                    طریق دریافت پیامک از اخبار جدید و تخفیف‌ها مطلع می‌شوید.
                  </h5>
                </div>
              </div>
              <div className="newsletter-box ">
                <form
                  onSubmit={(e) => {
                    _submitPhoneNumber(phoneNumber);
                    e.preventDefault();
                  }}
                >
                  <input
                    className="subscribe"
                    placeholder="شماره همراه خود را وارد کنید"
                    name="phonenumber"
                    id="subscribe"
                    type="text"
                    onChange={(p) => {
                      setPhoneNumber(p.target.value);
                    }}
                  />
                  <button
                    // onClick={() => { _submitPhoneNumber(phoneNumber) }}
                    type="submit"
                    className="submit"
                  >
                    اشتراک
                  </button>
                  {/* <div className="close-btn">
                    <a
                      onClick={() => {
                        store.dispatch(actionSetGreeting(false));
                      }}
                    >
                      تمایلی ندارم
                    </a>
                  </div> */}
                </form>
              </div>
            </div>
          </div>
          {/* Container End */}
        </div>
      </ReactModal>
    </div>
  );
}
