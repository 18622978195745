import React from 'react'
import Banners from '../Components/Main/Banners'
import BestProduct from '../Components/Main/BestProduct';
import BestSellerProdutcs from '../Components/Main/BestSellerProdutcs';
import BrandLogos from '../Components/Main/BrandLogos';
import CompanyPolicis from '../Components/Main/CompanyPolicis';
import CustomerGallery from '../Components/Main/CustomerGallery';
import FromBlogs from '../Components/Main/FromBlogs';
import NewProduct from '../Components/Main/NewProduct';
import Slider from '../Components/Main/Slider';


export default function Main() {
  return (
    <div >
      <Slider />
      <Banners />
      {/* <BestProduct /> */}
      <CustomerGallery />
      {/* <NewProduct /> */}
      <CompanyPolicis />
      {/* <BestSellerProdutcs /> */}
      {/* <FromBlogs /> */}
      <BrandLogos />
    </div>

  )
}
