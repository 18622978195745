import React from 'react'
import { Link } from 'react-router-dom'

export default function Account() {
    return (
       <div>
  {/* Breadcrumb Start */}
  <div className="breadcrumb-area ptb-60 ptb-sm-30">
    <div className="container">
      <div className="breadcrumb">
        <ul>
          <li><Link to="/">خانه</Link></li>
          <li className="active"><a>حساب کاربری</a></li>
        </ul>
      </div>
    </div>
    {/* Container End */}
  </div>
  {/* Breadcrumb End */}
  {/* My Account Page Start Here */}
  <div className="my-account white-bg pb-60">
    <div className="container">
      <div className="account-dashboard">
        <div className="dashboard-upper-info">
          <div className="row no-gutters align-items-center">
            <div className="col-lg-3 col-md-6">
              <div className="d-single-info">
                <p className="user-name">Hello <span>yourmail@info</span></p>
                <p>(not yourmail@info? <a href="#">Log Out</a>)</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="d-single-info">
                <p>Need Assistance? Customer service at.</p>
                <p>admin@example.com.</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="d-single-info">
                <p>E-mail them at </p>
                <p>support@example.com</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="d-single-info text-center">
                <a className="view-cart" href="cart.html"><i className="fa fa-cart-plus" aria-hidden="true" />view cart</a>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-2">
            {/* Nav tabs */}
            <ul className="nav flex-column dashboard-list" role="tablist">
              <li><a className="active" data-toggle="tab" href="#dashboard">Dashboard</a></li>
              <li><a data-toggle="tab" href="#orders">Orders</a></li>
              <li><a data-toggle="tab" href="#downloads">Downloads</a></li>
              <li><a data-toggle="tab" href="#address">Addresses</a></li>
              <li><a data-toggle="tab" href="#account-details">Account details</a></li>
              <li><a href="login.html">logout</a></li>
            </ul>
          </div>
          <div className="col-lg-10">
            {/* Tab panes */}
            <div className="tab-content dashboard-content mt-all-40">
              <div id="dashboard" className="tab-pane active">
                <h3>Dashboard </h3>
                <p>From your account dashboard. you can easily check &amp; view your <a href="#">recent orders</a>, manage your <a href="#">shipping and billing addresses</a> and <a href="#">edit your password and account details.</a></p>
              </div>
              <div id="orders" className="tab-pane fade">
                <h3>Orders</h3>
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Order</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Total</th>
                        <th>Actions</th>	 	 	 	
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>May 10, 2018</td>
                        <td>Processing</td>
                        <td>$25.00 for 1 item </td>
                        <td><a className="view" href="cart.html">view</a></td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>May 10, 2018</td>
                        <td>Processing</td>
                        <td>$17.00 for 1 item </td>
                        <td><a className="view" href="cart.html">view</a></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div id="downloads" className="tab-pane fade">
                <h3>Downloads</h3>
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Product</th>
                        <th>Downloads</th>
                        <th>Expires</th>
                        <th>Download</th>	 	 	 	
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Proecut Name Here</td>
                        <td>May 10, 2018</td>
                        <td>never</td>
                        <td><a className="view" href="#">Click Here To Download Your File</a></td>
                      </tr>
                      <tr>
                        <td>Proecut Name Here</td>
                        <td>Sep 11, 2018</td>
                        <td>never</td>
                        <td><a className="view" href="#">Click Here To Download Your File</a></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div id="address" className="tab-pane">
                <p>The following addresses will be used on the checkout page by default.</p>
                <h4 className="billing-address">Billing address</h4>
                <a className="view" href="#">edit</a>
                <p>steven smith</p>
                <p>Australia</p>   
              </div>
              <div id="account-details" className="tab-pane fade">
                <h3>Account details </h3>
                <div className="register-form login-form clearfix">
                  <form action="#">
                    <div className="form-group row align-items-center">
                      <label className="col-lg-3 col-md-4 col-form-label">Social title</label>
                      <div className="col-lg-6 col-md-8">
                        <span className="custom-radio"><input name="id_gender" defaultValue={1} type="radio" /> Mr.</span>
                        <span className="custom-radio"><input name="id_gender" defaultValue={1} type="radio" /> Mrs.</span>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="f-name" className="col-lg-3 col-md-4 col-form-label">First Name</label>
                      <div className="col-lg-6 col-md-8">
                        <input type="text" className="form-control" id="f-name" />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="l-name" className="col-lg-3 col-md-4 col-form-label">Last Name</label>
                      <div className="col-lg-6 col-md-8">
                        <input type="text" className="form-control" id="l-name" />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="email" className="col-lg-3 col-md-4 col-form-label">Email address</label>
                      <div className="col-lg-6 col-md-8">
                        <input type="text" className="form-control" id="email" />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="inputpassword" className="col-lg-3 col-md-4 col-form-label">Current password</label>
                      <div className="col-lg-6 col-md-8">
                        <input type="password" className="form-control" id="inputpassword" />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="newpassword" className="col-lg-3 col-md-4 col-form-label">New password</label>
                      <div className="col-lg-6 col-md-8">
                        <input type="password" className="form-control" id="newpassword" />
                        <button className="btn show-btn" type="button">Show</button>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="v-password" className="col-lg-3 col-md-4 col-form-label">Confirm password</label>
                      <div className="col-lg-6 col-md-8">
                        <input type="password" className="form-control" id="c-password" />
                        <button className="btn show-btn" type="button">Show</button>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="birth" className="col-lg-3 col-md-4 col-form-label">Birthdate</label>
                      <div className="col-lg-6 col-md-8">
                        <input type="text" className="form-control" id="birth" placeholder="MM/DD/YYYY" />
                      </div>
                    </div>
                    <div className="form-check row p-0 mt-20">
                      <div className="col-lg-6 col-lg-offset-3 col-md-8 col-md-offset-4">
                        <input className="form-check-input" defaultValue="#" id="offer" type="checkbox" />
                        <label className="form-check-label" htmlFor="offer">Receive offers from our partners</label>
                      </div>
                    </div>
                    <div className="form-check row p-0 mt-20">
                      <div className="col-lg-6 col-lg-offset-3 col-md-8 col-md-offset-4">
                        <input className="form-check-input" defaultValue="#" id="subscribe" type="checkbox" />
                        <label className="form-check-label" htmlFor="subscribe">Sign up for our newsletter<br />Subscribe to our newsletters now and stay up-to-date with new collections, the latest lookbooks and exclusive offers..</label>
                      </div>
                    </div>
                    <div className="register-box mt-40">
                      <button type="submit" className="return-customer-btn f-right">Save</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* My Account Page End Here */}
</div>

    )
}
