import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { errorToast } from "../Utils/TostiUtil";
import { validPhoneNumber } from "../Utils/validate";
import { registerUser } from "../services/user_services";

export default function Register() {
  const user = useSelector((state) => state.userReducer.user);
  const basket = useSelector((state) => state.shopBasketReducer.basket);
  const [fullName, setFullName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [passWord, setPassWord] = useState("");
  const [confrimPassWord, setConfirmPassWord] = useState("");
  const [sum, setSum] = useState(0);
  const [stringBasket, setStringBasket] = useState("");
  let history = useHistory()

  useEffect(() => {
    basket && _sumPrice();
    _stringToSend();
  }, [basket]);

  const _sumPrice = () => {
    var total = 0;
    for (var i = 0; i < basket.length; i++) {
      total = total + basket[i].price;
    }
    setSum(total);
  };

  const _stringToSend = () => {
    var string = "";
    for (var i = 0; i < basket.length; i++) {
      string =
        string +
        ` ${i + 1})  ${basket[i].productName} ${basket[i].orderDaysOrHours} ${basket[i].isDaily ? "روز" : 'ساعت"'
        } ${basket[i].deliveryReq === true ? "با حمل" : "بدون حمل"}\n`;
    }
    setStringBasket(
      `${string} \n قابل پرداخت: ${sum} \n ${fullName} \n ${mobile} \n ${email}`
    );
  };


  const _submitOrder = async () => {
    if (
      fullName.length === 0 ||
      mobile.length === 0 ||
      passWord.length === 0 ||
      confrimPassWord.length === 0
    ) { return errorToast("لطفا مشخصات خود را کامل وارد نمایید."); }
    if (!validPhoneNumber(mobile)) { return errorToast("شماره همراه صحیح نمی‌باشد.") }
    if (passWord !== confrimPassWord) return errorToast("تایید رمز عبور یکسان نیست")
    await registerUser({
      fullName,
      mobile,
      email,
      address,
      passWord,
    }).then(res => {
      console.log(res, 'resssssss');
      // eslint-disable-next-line react-hooks/rules-of-hooks
      history.push('/login')
    })
  }

  return (
    <div>
      {/* Breadcrumb Start */}
      <div className="breadcrumb-area ptb-60 ptb-sm-30">
        <div className="container">
          <div className="breadcrumb">
            <ul>
              <li>
                <Link to="/">خانه</Link>
              </li>
              <li className="active">
                <a>ثبت نام</a>
              </li>
            </ul>
          </div>
        </div>
        {/* Container End */}
      </div>
      {/* Breadcrumb End */}
      {/* Register Account Start */}
      <div style={{ direction: "rtl" }} className="register-account pb-60">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              {/* <div className="register-title">
                <h4
                  style={{ fontFamily: "BYekan", textAlign: "center" }}
                  className="mb-10"
                >
                  مبلغ قابل پرداخت: {numberWithCommas(sum)}
                </h4>
                <h3
                  style={{ fontFamily: "BYekan", textAlign: "right" }}
                  className="mb-10"
                >
                  ورود اطلاعات کاربر
                </h3>
              </div> */}
            </div>
          </div>
          {/* Row End */}
          <div className="row">
            <div className="col-sm-12">
              <form
                onSubmit={(event) => event.preventDefault()}
                className="form-horizontal"
                action="#"
              >
                <fieldset>
                  <legend style={{ fontFamily: "BYekan", textAlign: "right" }}>
                    فرم اطلاعات شخصی
                  </legend>
                  <div className="form-group">
                    <div style={{ marginTop: 10 }} className="col-sm-12">
                      <input
                        type="text"
                        className="form-control"
                        id="f-name"
                        placeholder="نام و نام خانوادگی"
                        onChange={(p) => {
                          setFullName(p.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div style={{ marginTop: 10 }} className="col-sm-12">
                      <input
                        type="mobile"
                        className="form-control"
                        id="mobile"
                        placeholder="شماره همراه"
                        onChange={(p) => {
                          setMobile(p.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div style={{ marginTop: 10 }} className="col-sm-12">
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="ایمیل"
                        onChange={(p) => {
                          setEmail(p.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div style={{ marginTop: 10 }} className="col-sm-12">
                      <input
                        type="address"
                        className="form-control"
                        id="address"
                        placeholder="آدرس"
                        onChange={(p) => {
                          setAddress(p.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div style={{ marginTop: 10 }} className="col-sm-12">
                      <input
                        type="password"
                        className="form-control"
                        id="password"
                        placeholder="رمز عبور"
                        onChange={(p) => {
                          setPassWord(p.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div style={{ marginTop: 10 }} className="col-sm-12">
                      <input
                        type="password"
                        className="form-control"
                        id="password"
                        placeholder="تکرار رمر عبور"
                        onChange={(p) => {
                          setConfirmPassWord(p.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="newsletter-btn">
                    <a
                      onClick={() => {
                        _submitOrder();
                      }}
                    >
                      ثبت نام
                    </a>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
          {/* Row End */}
        </div>
        {/* Container End */}
      </div>
      {/* Register Account End */}
    </div>
  );
}
